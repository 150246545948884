// $background-color: #3cb054;
$background-color: black;
$text-color: white;

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Roboto", sans-serif !important;
  color: $text-color !important;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.vertical-timeline::before {
  background: $background-color !important;
}

a,
a:hover,
a:focus,
a:active {
  outline: none;
  color: $text-color !important;
  text-decoration: none !important;
}

.m0 {
  margin: 0 !important;
}

.ppc {
  &-background {
    background-color: $background-color;
    background-image: linear-gradient(
      to top,
      $background-color 0%,
      darkgrey 100%
    );
    &__timeLine {
      background-color: $background-color;
    }
  }
  &-hero {
    height: 100vh;
    min-height: 550px;
  }
  &-arrow {
    &_down {
      position: absolute;
      bottom: 10px;
      right: 10px;
    }
  }
  &-tombstone {
    animation: shake 0.8s;
    &:hover {
      animation: shake 0.8s infinite;
    }
  }

  &-timeline {
    &_title {
      margin-left: 0.5rem;
    }
    &_text {
      font-size: 1.5rem;
      font-style: italic;
    }
    &_source {
      font-size: 0.8rem;
    }
  }

  @keyframes shake {
    0% {
      transform: translate(1px, 1px) rotate(0deg);
    }
    10% {
      transform: translate(-1px, -2px) rotate(-1deg);
    }
    20% {
      transform: translate(-3px, 0px) rotate(1deg);
    }
    30% {
      transform: translate(3px, 2px) rotate(0deg);
    }
    40% {
      transform: translate(1px, -1px) rotate(1deg);
    }
    50% {
      transform: translate(-1px, 2px) rotate(-1deg);
    }
    60% {
      transform: translate(-3px, 1px) rotate(0deg);
    }
    70% {
      transform: translate(3px, 1px) rotate(-1deg);
    }
    80% {
      transform: translate(-1px, -1px) rotate(1deg);
    }
    90% {
      transform: translate(1px, 2px) rotate(0deg);
    }
    100% {
      transform: translate(1px, -2px) rotate(-1deg);
    }
  }
}
